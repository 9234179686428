<template>
  <div
    class="newcharg"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    ref="main"
  >
    <div class="top">
      <div class="topline"></div>
      <div class="topmsg">
        <div class="plateno-box">
          <div class="plateno">
            <div class="icon"><img src="../../../assets/iconcar.png" /></div>
            <div class="num">{{ plateNo }}</div>
          </div>
          <div class="contact" v-if="serviceTel !== null">
            <van-button round type="info" size="mini" @click="handleContact"> 联系客服 </van-button>
          </div>
        </div>
        <div class="cash">
          <div class="money">
            <i style="font-size: 20px">￥</i>
            {{ paymentOrderDate.dataItems[0].paidFee }}
          </div>
          <div class="parkname">{{ parkingName ? parkingName : "停车场" }}</div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="top-foot">
        <div class="leftpoint"></div>
        <div class="line"></div>
        <div class="rightpoint"></div>
      </div>
      <div class="banner-msg">
        <div class="message" style="margin-bottom: 10px">
          <div style="font-weight: bold; color: black">费用详情</div>
          <div></div>
        </div>
        <div class="message" style="margin-bottom: 10px">
          <div>驶入时间</div>
          <div>{{ paymentOrderDate.dataItems[0].enterTime }}</div>
        </div>
        <div class="message" style="margin-bottom: 10px">
          <div>驶出时间</div>
          <div>{{ paymentOrderDate.dataItems[0].leaveTime }}</div>
        </div>
        <div class="message" style="margin-bottom: 10px" v-if="type == 5">
          <div>停车时长</div>
          <div>{{ paymentOrderDate.dataItems[0].useTime | formatMin }}</div>
        </div>
        <div class="message" style="margin-bottom: 10px" v-else>
          <div>停车时长</div>
          <div>{{ paymentOrderDate.dataItems[0].useTime | formatSeconds }}</div>
        </div>
        <div class="message" style="margin-bottom: 10px">
          <div>订单金额</div>
          <div style="color: #2370ff">￥{{ paymentOrderDate.dataItems[0].payableFee }}</div>
        </div>
        <div class="message">
          <div>优惠金额</div>
          <div style="color: #eb5757">-￥{{ paymentOrderDate.dataItems[0].discountFee }}</div>
        </div>
        <div class="coupon">
          <van-cell
            class="coupon-box"
            title="优惠券"
            is-link
            :value="
              paymentOrderDate.dataItems[0].paidFee == savePaymentAmount
                ? allCouponList.length
                  ? '有可用券'
                  : '无可用券'
                : '-￥' + (savePaymentAmount - paymentOrderDate.dataItems[0].paidFee)
            "
            @click="handleCouponOpen"
          >
            <template #title>
              <span class="custom-title">
                <img
                  style="width: 18px; height: 18px; vertical-align: text-top"
                  src="../../../assets/coupon-icon.png"
                />
                优惠券
              </span>
              <van-tag plain type="danger">{{ claimable ? claimable : "" }}</van-tag>
            </template>
          </van-cell>
        </div>
        <div class="message" style="margin-bottom: 17px">
          <div>共计优惠</div>
          <div style="color: #eb5757">-￥{{ paymentOrderDate.dataItems[0].discountFee + deduction }}</div>
        </div>
      </div>
      <div class="top-foot">
        <div class="leftpoint"></div>
        <div class="line"></div>
        <div class="rightpoint"></div>
      </div>
      <div class="foot-sumbmit">
        <!-- 
          :style="{
            backgroundColor:
              paymentOrderDate.dataItems[0].paidFee == 0 ? '#E5E5E5' : arrearList.length ? '#E5E5E5' : '#2370ff',
            color: paymentOrderDate.dataItems[0].paidFee == 0 ? '#A3A3A3' : arrearList.length ? '#A3A3A3' : '#fff',
            boxShadow:
              paymentOrderDate.dataItems[0].paidFee == 0
                ? 'none'
                : arrearList.length
                ? 'none'
                : '0px 2px 10px rgba(35, 112, 255, 0.4)',
          }"
         -->
        <button
          class="sumbmit-btn"
          :style="{
            backgroundColor: savePaymentAmount == 0 ? '#E5E5E5' : arrearList.length ? '#E5E5E5' : '#2370ff',
            color: savePaymentAmount == 0 ? '#A3A3A3' : arrearList.length ? '#A3A3A3' : '#fff',
            boxShadow:
              savePaymentAmount == 0 ? 'none' : arrearList.length ? 'none' : '0px 2px 10px rgba(35, 112, 255, 0.4)',
          }"
          @click="payHandle"
          v-preventReClick="3000"
          :disabled="savePaymentAmount == 0 ? true : false"
        >
          立即付款
        </button>
        <div class="free-text" v-if="savePaymentAmount == 0 ? true : false">当前处于免费时段，可以直接出场</div>
      </div>
    </div>
    <!-- 停车场欠费追缴 -->
    <div class="arrears" v-if="parklotArrearList.length">
      <div class="arrear-title">停车场历史欠费</div>
      <div class="arrear-list" v-for="(arrear, index) in parklotArrearList" :key="index">
        <div class="toptext">
          <div class="parkname">
            <span>{{ parklotNameparklotName[arrear.itemId] }} </span>
            /
            <span> {{ arrear.arrearCount }}笔 </span>
          </div>
          <!-- <div class="details" @click="parklotArreardetalis(arrear.itemId, arrear.recordNo)">
            详情<i class="el-icon-arrow-right"></i>
          </div> -->
        </div>
        <div class="foottext">
          <div class="cashnum">
            合计：
            <span style="color: #2370ff"> ￥{{ (arrear.arrearSum * 1) | rounding }} </span>
          </div>
          <button class="pay" @click="parklotArreardetalis(arrear.itemId, arrear.recordNo)">去支付</button>
        </div>
        <div class="arrear-line"></div>
      </div>
    </div>
    <!-- 路侧欠费追缴 -->
    <div class="arrears" v-if="arrearList.length">
      <div class="arrear-title">路侧历史欠费</div>
      <div class="arrear-list" v-for="(arrear, index) in arrearList" :key="index">
        <div class="toptext">
          <div class="parkname">
            <span>{{ arrear.parkName }} </span>
            /
            <span> {{ arrear.count }}笔 </span>
          </div>
          <!-- <div class="details" @click="todetalis(arrear.appId, arrear.itemId, arrear.plateNo)">
            详情<i class="el-icon-arrow-right"></i>
          </div> -->
        </div>
        <div class="foottext">
          <div class="cashnum">
            合计：
            <span style="color: #2370ff"> ￥{{ (arrear.amount * 1) | rounding }} </span>
          </div>
          <button class="pay" @click="todetalis(arrear.appId, arrear.itemId, arrear.plateNo)">去支付</button>
        </div>
        <div class="arrear-line"></div>
      </div>
    </div>
    <div class="foot-line"></div>
    <!-- 优惠券框 -->
    <van-popup
      v-model="couponshow"
      closeable
      round
      position="bottom"
      :style="{ height: activityList.length ? '80%' : allCouponList.length ? '70%' : '30%' }"
      class="coupon-popup"
      @click-close-icon="couponCancel"
    >
      <div class="getcoupon-list" v-if="activityList.length">
        <div class="getcoupon-title">
          <img src="../../../assets/getcoupon-title.png" />
        </div>
        <div class="getcoupon-view" v-for="(item, index) in activityList" :key="index">
          <div class="left">
            <div class="getcoupon-view-top">{{ item.name }}</div>
            <div class="getcoupon-view-middle">
              {{
                item.type == 1
                  ? "授权手机号注册会员，体验更多功能"
                  : item.type == 2
                  ? "关注企业公众号，停车有位更优惠"
                  : item.type == 3
                  ? "停车订单在线支付即可领取"
                  : item.type == 4
                  ? "回馈新老用户，免费领券，限量供应"
                  : "免费领取优惠券"
              }}
            </div>
            <div class="getcoupon-view-bottom">
              <div class="coupon-count" v-for="(list, idnex) in item.bzEquitiesGoodsList" :key="idnex">
                <div class="coupon-count-left">{{ list.typeToString }}</div>
                <div class="coupon-count-right">{{ list.valueToString }}</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="getbtn" @click="getActiveityCoupon(item.type, item.id)">
              <div>
                {{
                  item.type == 1
                    ? "注册"
                    : item.type == 2
                    ? "关注"
                    : item.type == 3
                    ? "支付"
                    : item.type == 4
                    ? "免费"
                    : "立刻"
                }}
              </div>
              <div>领取</div>
            </div>
          </div>
        </div>
      </div>
      <div class="gap" v-if="activityList.length"></div>
      <div class="coupon-list">
        <div v-if="allCouponList.length">
          <div class="coupon-title">可用优惠券</div>
          <van-divider style="margin: 0; padding: 15px 0" :hairline="false" />
          <div class="checkmsg">
            您已选择{{ paymentOrderDate.dataItems[0].paidFee == savePaymentAmount ? 0 : 1 }}张优惠券，可抵扣
            <span style="color: #ff7627">
              {{ "￥" + deduction }}
            </span>
          </div>
          <van-radio-group v-model="couponCheck" @change="changeRad">
            <div v-for="(item, index) in allCouponList" :key="index">
              <div class="coupon-view" :class="{ active: couponClickIndex == index }">
                <div class="expired-label" v-if="item.beAlmostExpired">
                  <img src="../../../assets/coupon-expired-label.png" alt="" />
                </div>
                <div class="semi-circle-top" :class="{ 'active-topround': couponClickIndex == index }"></div>
                <div class="semi-circle-bottom" :class="{ 'active-bottomround': couponClickIndex == index }"></div>
                <div class="coupon-type">{{ couponTypeList[item.equitiesType] }}</div>
                <div class="coupon-msg">
                  <div class="coupon-favour-left" v-if="item.equitiesType !== 2">
                    <div style="font-size: 30px">
                      {{ item.value }}
                      <span style="font-size: 15px">
                        {{
                          item.equitiesType == 1
                            ? "元"
                            : item.equitiesType == 2
                            ? "免单"
                            : item.equitiesType == 3
                            ? "元"
                            : "折"
                        }}
                      </span>
                    </div>
                    <div>{{ item.conditionToString }}</div>
                  </div>
                  <div class="coupon-favour-left" v-else>
                    <div style="font-size: 18px; font-weight: 550">免单体验</div>
                    <div>({{ item.valueToString }})</div>
                    <div>{{ item.conditionToString }}</div>
                  </div>
                  <div class="coupon-favour-right">
                    <div class="favour-dsc">{{ item.equitiesName }}</div>
                    <div class="select">
                      <van-radio :name="item.id" checked-color="#FFA763" @click="radClick(index)"></van-radio>
                    </div>
                    <div class="favour-date">
                      <div>
                        有效期至：
                        {{ item.validityTime }}
                      </div>
                      <!-- <div>详情<van-icon name="arrow" /></div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-radio-group>
          <van-divider>暂无更多</van-divider>
        </div>
        <van-empty v-else description="暂无可用优惠券" />
      </div>
      <div class="coupon-define" v-if="allCouponList.length">
        <van-button round block class="cancel" @click="couponCancel">取消</van-button>
        <van-button round block class="submit" @click="couponsumbit">确定</van-button>
      </div>
    </van-popup>

    <SigninCouponAlert :sendshow="signinShow" @destroyed="alertDestroyed(1)"></SigninCouponAlert>
    <GetCouponAlert :sendshow="getCouponShow" @destroyed="alertDestroyed(4)"></GetCouponAlert>
    <GetSuccess :sendshow="getSuccessShow" @destroyed="alertDestroyed(23)"></GetSuccess>
  </div>
</template>

<script>
import _ from "lodash";
import { Dialog } from "vant";
import * as math from "mathjs";
import SigninCouponAlert from "../../../component/signinCouponAlert";
import GetCouponAlert from "../../../component/getCouponAlert";
import GetSuccess from "../../../component/getSuccess";

export default {
  name: "newcharging",
  components: {
    [Dialog.Component.name]: Dialog.Component,
    SigninCouponAlert,
    GetCouponAlert,
    GetSuccess,
  },
  // 过滤器
  filters: {
    // 秒过滤器
    formatSeconds(value) {
      let result = parseInt(value);
      // 天
      let y = Math.floor(result / 86400) < 10 ? "0" + Math.floor(result / 86400) : Math.floor(result / 86400);
      // 时
      let h =
        Math.floor((result / 3600) % 24) < 10
          ? "0" + Math.floor((result / 3600) % 24)
          : Math.floor((result / 3600) % 24);
      // 分
      let m =
        Math.floor((result / 60) % 60) < 10 ? "0" + Math.floor((result / 60) % 60) : Math.floor((result / 60) % 60);
      // 秒
      let s = Math.floor(result % 60) < 10 ? "0" + Math.floor(result % 60) : Math.floor(result % 60);

      let res = "";
      if (y !== "00") res += `${y}天`;

      if (h !== "00") res += `${h}时`;

      if (m !== "00") res += `${m}分`;

      res += `${s}秒`;

      return res;
    },
    // 分钟过滤器
    formatMin(val) {
      let result = parseInt(val);
      // 天
      let y = Math.floor(result / 60 / 24) < 10 ? "0" + Math.floor(result / 60 / 24) : Math.floor(result / 60 / 24);
      // 时
      let h =
        Math.floor((result / 60) % 24) < 10 ? "0" + Math.floor((result / 60) % 24) : Math.floor((result / 60) % 24);
      // 分
      let m = Math.floor(result % 60) < 10 ? "0" + Math.floor(result % 60) : Math.floor(result % 60);

      let res = "";
      if (y !== "00") res += `${y}天`;

      if (h !== "00") res += `${h}时`;

      if (m !== "00") res += `${m}分`;

      return res;
    },
  },
  data() {
    return {
      loading: false,
      paymentOrderDate: {},
      parkingName: localStorage.getItem("parkingName"),
      plateNo: localStorage.getItem("plateNo"),
      type: localStorage.getItem("type"),
      // 欠费列表
      arrearList: [],
      paid: false,
      // 停车场欠费列表
      parklotArrearList: [],
      // 停车场名称
      parklotNameparklotName: {},
      // 可领取券
      claimable: "",
      // 优惠券框是否展示
      couponshow: false,
      // 活动券
      activityList: [],
      // 优惠券
      allCouponList: [],
      // 选中的优惠券
      couponCheck: "",
      // 选中的优惠券下标
      couponClickIndex: -1,
      couponTypeList: {
        1: "现金券",
        2: "免单券",
        3: "满减券",
        4: "折扣券",
      },
      // 注册优惠券是否展示
      signinShow: false,
      // 领券是否展示
      getCouponShow: false,
      // 领取成功是否展示
      getSuccessShow: false,
      savePaymentAmount: 0,
      // 抵扣金额
      deduction: 0,
      // 优惠券选中变化
      couponRaidChange: false,
      domain: process.env.VUE_APP_TESTLOCAL_DOMAIN,
    };
  },
  watch: {
    // signinShow: {
    //   handler(val) {
    //     val ? (this.$refs.main.style.filter = `blur(2px)`) : (this.$refs.main.style.filter = null);
    //   },
    // },
    // getCouponShow: {
    //   handler(val) {
    //     val ? (this.$refs.main.style.filter = `blur(2px)`) : (this.$refs.main.style.filter = null);
    //   },
    // },
  },
  created() {
    this.paymentOrderDate = JSON.parse(this.$route.query.orderDate);
    this.savePaymentAmount = JSON.parse(this.$route.query.orderDate).dataItems[0].paidFee;
    this.getArrearList();
    this.getParklortArrearList();
    this.getCouponList();
  },
  methods: {
    // 联系客服
    handleContact() {
      window.location.href = `tel:${this.serviceTel}`;
    },
    handleCouponOpen() {
      this.couponshow = true;
      this.getCouponList();
    },
    // 优惠券框选择变化
    changeRad() {
      this.couponRaidChange = true;
    },
    // 优惠券选择
    radClick(index) {
      this.couponClickIndex = index;
      if (!this.couponRaidChange) {
        this.couponCheck = "";
        this.couponClickIndex = -1;
      }
      this.couponRaidChange = false;
    },
    // 优惠券选择取消
    couponCancel() {
      this.couponshow = false;
      this.couponCheck = "";
      this.checkOrderId = null;
    },
    // 领取弹框关闭感知
    alertDestroyed(type) {
      let openId = localStorage.getItem("openId");
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      this.getCouponList();
      // 查询是否可领券
      this.$axios
        .post(`${this.domain}/api/opercenter/queryBzActivityOfParkPayAccessibleLabel`, {
          appId,
          payAmount: this.savePaymentAmount,
          itemId,
          openId: openId ? openId : null,
        })
        .then((res) => {
          if (res.data.data.availableEquities) {
            this.claimable = res.data.data.availableEquities;
          }
        });
      if (type == 1) {
        this.signinShow = false;
      }
      if (type == 4) {
        this.getCouponShow = false;
      }
    },
    // 活动券领取
    getActiveityCoupon(type, id) {
      this.couponshow = false;
      if (type == 1) {
        this.signinShow = true;
        this.getCouponShow = false;
        this.getSuccessShow = false;
      }
      if (type == 2) {
        let appId = localStorage.getItem("appId");
        let openId = localStorage.getItem("openId");
        let alipayuserId = localStorage.getItem("alipayuserId");
        this.$axios.post(`${this.domain}/api/opercenter/disposeFocusOfficial`, {
          appId,
          openId: openId ? openId : alipayuserId,
          id,
        });
        this.$axios
          .post(`${this.domain}/api/opercenter/queryUninBase64`, {
            appId,
          })
          .then((res) => {
            let url = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=${res.data.data}&scene=110#wechat_redirect`;
            location.href = url;
          });
      }
      if (type == 3) {
        this.$toast("支付订单即可领取到优惠券");
      }
      if (type == 4) {
        let sign = this.activityList.filter((item) => item.type === 1);
        let follow = this.activityList.filter((item) => item.type === 2);
        if (sign.length > 0) {
          this.$toast("请先领取用户注册领取优惠券活动");
        } else if (follow.length > 0) {
          this.$toast("请先领取用户关注公众号领取优惠券活动");
        } else {
          this.signinShow = false;
          this.getCouponShow = true;
          this.getSuccessShow = false;
        }
      }
    },
    // 优惠券确认
    couponsumbit() {
      let newList = this.allCouponList.filter((item) => item.id === this.couponCheck);
      if (newList.length) {
        this.paymentOrderDate.dataItems[0].paidFee = math.subtract(
          math.bignumber(this.savePaymentAmount),
          math.bignumber(newList[0].deduction)
        );
        this.deduction = newList[0].deduction;
      } else {
        this.couponCheck = "";
        this.paymentOrderDate.dataItems[0].paidFee = this.savePaymentAmount;
        this.deduction = 0;
      }
      this.couponshow = false;
    },
    // 优惠券请求
    getCouponList() {
      let openId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      let { savePaymentAmount } = this;
      let recordNo = this.paymentOrderDate.dataItems[0].recordNo;
      this.$axios
        .post(`${this.domain}/api/opercenter/queryH5EquitiesGoodsByUserOfPlate`, {
          appId,
          itemId,
          openId: openId ? openId : null,
          payAmount: savePaymentAmount,
          outTradeOrder: recordNo,
          parkingTime: this.paymentOrderDate.dataItems[0].enterTime,
        })
        .then((res) => {
          this.allCouponList = res.data.data;
        })
        .catch(() => {
          this.$toast.fail("优惠券加载失败");
        });
      // 活动列表
      this.$axios
        .post(`${this.domain}/api/opercenter/queryBzActivityOfParkPay`, {
          appId,
          openId: openId ? openId : null,
        })
        .then((res) => {
          if (res.data.data) {
            this.activityList = res.data.data;
            // 活动领取反馈
            this.$axios.post(`${this.domain}/api/opercenter/queryActivityOfH5FeedBack`, {
              appId,
              openId: openId ? openId : null,
            });
          }
        });
      // 查询是否可领券
      this.$axios
        .post(`${this.domain}/api/opercenter/queryBzActivityOfParkPayAccessibleLabel`, {
          appId,
          payAmount: savePaymentAmount,
          itemId,
          openId: openId ? openId : null,
        })
        .then((res) => {
          if (res.data.data.availableEquities) {
            this.claimable = res.data.data.availableEquities;
          }
        });
    },
    // 停车场详情欠费支付
    parklotArreardetalis(itemId) {
      this.$router.push({
        path: "/outpark/parklotdetail",
        query: {
          parkName: this.parklotNameparklotName[itemId],
          itemId: itemId,
        },
      });
    },
    // 停车场欠费查询
    async getParklortArrearList() {
      let { plateNo } = this;
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      await this.$axios.get(`${this.domain}/api/parklotPay/queryItemInfoParkingName`).then((res) => {
        res.data.data.forEach((item) => {
          this.parklotNameparklotName[item.itemId] = item.parkingName;
        });
      });
      this.$axios
        .post(`${this.domain}/api/parklotPay/queryArrearListForH5`, {
          itemId,
          appId,
          plateNo,
        })
        .then((res) => {
          if (res.data.data != null) {
            this.parklotArrearList = res.data.data;
          } else {
            this.parklotArrearList = [];
          }
        });
    },
    // 欠费详情
    todetalis(appId, itemId, plateNo, recordNo) {
      this.$router.push({
        path: "/outpark/arreardetails",
        query: {
          appId,
          itemId,
          plateNo,
          recordNo,
        },
      });
    },
    // 欠费列表
    getArrearList() {
      let appId = localStorage.getItem("appId");
      let { plateNo } = this;
      this.$axios
        .post(`${this.domain}/api/parkPay/queryRoadArrear`, {
          appId,
          plateNo,
        })
        .then((res) => {
          if (res.data.data != null) {
            this.arrearList = res.data.data;
          } else {
            this.arrearList = [];
          }
        });
      this.$axios
        .post(`${this.domain}/applet/customer-service-number`, {
          appId,
        })
        .then((res) => {
          if (res.data.data !== null) {
            this.serviceTel = res.data.data[0].phoneNumber;
          }
        });
    },
    bringupPay() {
      let { type, paymentOrderDate, arrearList, parklotArrearList } = this;
      let openId = localStorage.getItem("openId");
      let userId = localStorage.getItem("alipayuserId");
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      let channelId = localStorage.getItem("channelId");
      this.$axios.get(`${this.domain}/api/jinhua/select/${appId}/${itemId}`).then((res) => {
        if (res.data.data !== null) {
          this.loading = true;
          this.$axios
            .post(`${this.domain}/api/jinhua/parkPay`, {
              appId,
              itemId,
              clientIp: "127.0.0.1",
              total_fee: paymentOrderDate.dataItems[0].paidFee,
              recordNo: paymentOrderDate.dataItems[0].recordNo,
              total: paymentOrderDate.dataItems[0].payableFee,
              reduce: paymentOrderDate.dataItems[0].discountFee,
              useTime: paymentOrderDate.dataItems[0].useTime,
              type,
              channelId: channelId == "undefined" ? null : channelId,
              method: openId ? 1 : 2,
            })
            .then((res) => {
              this.loading = false;
              window.location.href = res.data.data;
            });
        } else {
          if (openId) {
            this.wxSettlement();
          }
          if (userId) {
            this.aliSettlement();
          }
        }
      });
    },
    // 立即付款按钮
    payHandle: _.debounce(function () {
      let { arrearList, parklotArrearList } = this;
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      if (arrearList.length) {
        Dialog.alert({
          message: `请先支付路段欠费订单后，再支付出场订单`,
        }).then(() => {
          // on close
        });
      } else if (parklotArrearList.length) {
        this.$axios
          .post(`${this.domain}/api/parklotPay/queryArrearMandatoryPayment`, {
            appId,
            itemId,
          })
          .then((res) => {
            if (res.data.data.isMandatory == "true") {
              Dialog.alert({
                message: `请先支付停车场欠费订单后，再支付出场订单`,
              }).then(() => {
                // on close
              });
            } else {
              this.bringupPay();
            }
          });
      } else {
        this.bringupPay();
      }
    }, 100),
    // 支付宝结算
    aliSettlement() {
      let { paymentOrderDate } = this;
      let userId = localStorage.getItem("alipayuserId");
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      let { type } = this;
      let channelId = localStorage.getItem("channelId");
      let deduction;
      let codes;
      if (this.couponCheck !== null && this.couponCheck !== "") {
        deduction = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].deduction;
        code = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].code;
        // deduction.push(this.allCouponList.filter((item) => item.id === this.couponCheck)[0].deduction);
        // codes.push(this.allCouponList.filter((item) => item.id === this.couponCheck)[0].code);
      } else {
        deduction = 0;
        codes = 0;
      }
      // 总支付金额
      // let deductionCount = math.add(
      //   math.bignumber(paymentOrderDate.dataItems[0].discountFee),
      //   math.bignumber(deduction)
      // );
      this.$axios
        .post(`${this.domain}/api/pay/parkAliOrder`, {
          itemId,
          appId,
          ifCode: "alipay",
          openId: userId,
          clientIp: "127.0.0.1",
          total_fee: paymentOrderDate.dataItems[0].paidFee + "",
          recordNo: paymentOrderDate.dataItems[0].recordNo,
          total: paymentOrderDate.dataItems[0].payableFee,
          reduce: paymentOrderDate.dataItems[0].discountFee,
          useTime: paymentOrderDate.dataItems[0].useTime,
          type,
          channelId: channelId == "undefined" ? null : channelId,
          deduction,
          codes,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.tradePay(`${res.data.trade_no}`);
            this.paid = true;
          } else if (res.code == 404) {
            Dialog.alert({
              message: `${res.data.msg}`,
            }).then(() => {
              AlipayJSBridge.call("closeWebview");
            });
          } else {
            Dialog.alert({
              message: `${res.data.msg}`,
            }).then(() => {
              // on close
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    // 支付宝收银台
    // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener("AlipayJSBridgeReady", callback, false);
      }
    },
    tradePay(tradeNO) {
      let that = this;
      this.ready(() => {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: tradeNO,
          },
          (data) => {
            if (data.resultCode === "9000") {
              that.getArrearList();
              if (that.paid) {
                AlipayJSBridge.call("closeWebview");
              }
            } else if (data.resultCode === "6001") {
              let recordNo = sessionStorage.getItem("recordNo");
              this.$axios
                .post(`${this.domain}/api/pay/cancelParkPay`, {
                  recordNo,
                })
                .then((res) => {});
            } else {
              that.paid = false;
              alert("支付失败，请重新扫码");
            }
          }
        );
      });
    },
    // 微信结算
    wxSettlement() {
      let { paymentOrderDate } = this;
      let openId = localStorage.getItem("openId");
      let appId = localStorage.getItem("appId");
      let itemId = localStorage.getItem("itemId");
      let { type } = this;
      let channelId = localStorage.getItem("channelId");
      let deduction;
      let code;
      if (this.couponCheck !== null && this.couponCheck !== "") {
        deduction = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].deduction;
        code = this.allCouponList.filter((item) => item.id === this.couponCheck)[0].code;
        // deduction.push(this.allCouponList.filter((item) => item.id === this.couponCheck)[0].deduction);
        // codes.push(this.allCouponList.filter((item) => item.id === this.couponCheck)[0].code);
      } else {
        deduction = 0;
        code = 0;
      }
      // 总支付金额
      // let deductionCount = math.add(
      //   math.bignumber(paymentOrderDate.dataItems[0].discountFee),
      //   math.bignumber(deduction)
      // );
      this.$axios
        .post(`${this.domain}/api/pay/parkWxOrder`, {
          itemId,
          appId,
          ifCode: "wxpay",
          openId,
          clientIp: "127.0.0.1",
          total_fee: paymentOrderDate.dataItems[0].paidFee + "",
          recordNo: paymentOrderDate.dataItems[0].recordNo,
          total: paymentOrderDate.dataItems[0].payableFee,
          reduce: paymentOrderDate.dataItems[0].discountFee,
          useTime: paymentOrderDate.dataItems[0].useTime,
          type,
          channelId: channelId == "undefined" ? null : channelId,
          deduction,
          code,
        })
        .then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            this.weixinPay(data);
            this.paid = true;
          } else if (res.data.code == 404) {
            Dialog.alert({
              message: `${res.data.msg}`,
            }).then(() => {
              WeixinJSBridge.call("closeWindow");
            });
          } else {
            Dialog.alert({
              message: `${res.data.msg}`,
            }).then(() => {
              // on close
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    // 微信收银台
    //解决微信内置对象报错
    weixinPay(data) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", that.onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady(data));
        }
      } else {
        that.onBridgeReady(data);
      }
    },
    // 微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(data) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", data, (res) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          this.getArrearList();
          if (this.paid) {
            WeixinJSBridge.call("closeWindow");
          }
        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
          this.paid = false;
          let recordNo = sessionStorage.getItem("recordNo");
          this.$axios
            .post(`${this.domain}/api/pay/cancelParkPay`, {
              recordNo,
            })
            .then((res) => {});
          alert("支付已取消，请重新扫码");
        } else {
          this.paid = false;
          this.$router.push("/inpark/fail");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
.newcharg {
  padding: 15px;
  min-height: 100vh;
  background-color: rgb(248, 248, 248);

  .top {
    width: 100%;
    .topline {
      height: 20px;
      background-image: url("../../../assets/topline.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .topmsg {
      padding: 5px 0 15px 15px;
      background-color: #fff;

      .plateno-box {
        display: flex;
        justify-content: space-between;
        .plateno {
          display: flex;
          align-items: center;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            // line-height: 20px;
            text-align: center;
            background-color: #2370ff;
            border-radius: 50%;

            img {
              width: 12px;
              height: 10px;
            }
          }

          .num {
            margin-left: 5px;
            font-size: 18px;
            color: #333333;
          }
        }

        .contact {
          margin-right: 10px;
        }
      }

      .cash {
        margin: 15px 0;
        text-align: center;

        .money {
          font-size: 35px;
          color: #2370ff;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .parkname {
          font-size: 14px;
          color: #a3a3a3;
        }
      }
    }
  }

  .banner {
    // margin-bottom: 10px;

    .top-foot {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      height: 16px;
      background-color: #fff;

      .leftpoint {
        position: absolute;
        left: 0;
        transform: translate(-50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }

      .line {
        margin: 0 20px;
        width: 100%;
        border: 1px dashed #e9e7e7;
      }

      .rightpoint {
        position: absolute;
        right: 0;
        transform: translate(50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }
    }

    .banner-msg {
      padding: 17px 15px 10px 15px;
      background-color: #fff;

      .message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        color: #a3a3a3;
      }

      .coupon {
        margin: 10px 0;
        background-color: #fff;
        vertical-align: baseline;

        .coupon-box {
          padding: 10px;
          height: 40px;
          background-color: rgba(235, 87, 87, 0.05);
          border-radius: 5px;
          color: #eb5757;
          font-size: 15px;
          font-weight: 600;
        }

        .img {
          width: 18px;
          height: 18px;

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .foot-sumbmit {
      padding: 10px 15px 17px 15px;
      background-color: #fff;

      .sumbmit-btn {
        display: block;
        outline: none;
        border: none;
        width: 315px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        background-color: #2370ff;
        border-radius: 22px;
      }

      .free-text {
        margin-top: 10px;
        font-size: 13px;
        color: #2370ff;
        text-align: center;
      }
    }
  }

  .footer {
    .top-foot {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      height: 16px;
      background-color: #fff;

      .leftpoint {
        position: absolute;
        left: 0;
        transform: translate(-50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }

      .line {
        margin: 0 auto;
        width: 300px;
        border: 1px dashed #e9e7e7;
      }

      .rightpoint {
        position: absolute;
        right: 0;
        transform: translate(50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }
    }
  }

  .foot-line {
    height: 20px;
    background-image: url("../../../assets/footline.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .arrears {
    padding: 17px 15px 15px;
    background-color: #fff;

    .arrear-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .arrear-list {
      box-sizing: border-box;
      padding: 10px 0;
      height: 90px;

      .toptext {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .parkname {
          font-size: 15px;
          color: #333333;
        }

        .details {
          text-align: center;
          font-size: 15px;
          color: #a3a3a3;
        }
      }

      .foottext {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cashnum {
          font-weight: 500;
          font-size: 15px;
          color: #333333;
        }

        .pay {
          font-size: 15px;
          outline: none;
          border: none;
          width: 80px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          border-radius: 5px;
          background-color: #2370ff;
          color: #fff;
        }
      }

      .arrear-line {
        margin-top: 10px;
        border: 1px dashed #e5e5e5;
      }
    }
  }

  .coupon-popup {
    padding-bottom: 80px;

    .getcoupon-list {
      padding: 0 15px;

      .getcoupon-title {
        display: flex;
        justify-content: center;
        padding: 15px 0;

        img {
          width: 176px;
          height: 20px;
        }
      }

      .getcoupon-view {
        display: flex;
        margin-top: 10px;
        width: 100%;
        min-height: 90px;
        background: url("../../../assets/getcoupon-listBG.png") no-repeat;
        background-size: 100% 100%;

        .left {
          box-sizing: border-box;
          padding: 10px 0 17px 17px;
          width: 80%;
          min-height: 90px;

          .getcoupon-view-top {
            color: #b45227;
            font-size: 16px;
            font-weight: 600;
          }

          .getcoupon-view-middle {
            font-size: 13px;
            color: #b45227;
          }

          .getcoupon-view-bottom {
            width: 100%;
            margin-top: 6px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .coupon-count {
              display: flex;
              height: 20px;
              border: 1px solid rgb(254, 174, 94);
              border-radius: 3px;
              font-size: 12px;

              .coupon-count-left {
                padding: 2px 5px;
                background: linear-gradient(90deg, rgb(250, 86, 45), rgb(254, 174, 94) 100%);
                color: #fefbca;
                white-space: nowrap;
              }

              .coupon-count-right {
                padding: 2px 5px;
                color: #ec8e31;
                white-space: nowrap;
              }
            }
          }
        }

        .right {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25%;
          min-height: 90px;

          .getbtn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: linear-gradient(135deg, rgb(255, 239, 216), rgb(255, 206, 114) 100%);
            border: 2px solid rgba(255, 118, 39, 0.5);
            font-size: 13px;
            color: #cd4104;
            font-weight: 600;
            text-align: center;
            white-space: pre-wrap;
          }
        }
      }
    }

    .gap {
      margin-top: 10px;
      width: 100%;
      height: 10px;
      background-color: #f8f8f8;
    }

    .coupon-list {
      padding: 0 15px;

      .coupon-title {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        font-size: 18px;
      }

      .checkmsg {
        font-size: 15px;
        color: #333333;
      }

      .coupon-view {
        position: relative;
        box-sizing: border-box;
        margin-top: 10px;
        position: relative;
        width: 100%;
        min-height: 90px;
        border-radius: 10px;
        border: 0.5px solid rgba(255, 118, 39, 0.5);

        .expired-label {
          position: absolute;
          top: -4px;
          right: -4px;

          img {
            width: 60px;
            height: 34px;
          }
        }

        .semi-circle-top {
          position: absolute;
          top: -1px;
          left: 84px;
          width: 12px;
          height: 6px;
          background-color: #fff;
          border-radius: 0 0 10px 10px;
          border: 1px solid rgba(255, 118, 39, 0.5);
          border-top: 2px solid #fff;
        }

        .semi-circle-bottom {
          position: absolute;
          bottom: -1px;
          left: 84px;
          width: 12px;
          height: 6px;
          background-color: #fff;
          border-radius: 10px 10px 0 0;
          border: 1px solid rgba(255, 118, 39, 0.5);
          border-bottom: 2px solid #fff;
        }

        .coupon-type {
          position: absolute;
          top: -1px;
          left: -2px;
          width: 60px;
          height: 22px;
          line-height: 22px;
          background: url("../../../assets/coupon-typeBG.png") no-repeat;
          color: #ff7627;
          padding-left: 9px;
        }

        .coupon-msg {
          display: flex;
          justify-content: space-between;
          padding: 7px;
          padding-left: 0;

          .coupon-favour-left {
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            margin-top: 8px;
            width: 90px;
            height: 60px;
            text-align: center;
            font-size: 12px;
            color: #ff7627;
            border-right: 1px dashed #ff7627;
          }

          .coupon-favour-right {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            padding: 5px 10px;
            width: 240px;
            height: 76px;
            border-radius: 8px;
            background: rgba(255, 118, 39, 0.05);

            .favour-dsc {
              width: 90%;
              color: #333333;
              font-size: 15px;
              font-weight: 600;
            }

            .select {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10px;
            }

            .favour-date {
              display: flex;
              justify-content: space-between;
              color: #a3a3a3;
              font-size: 12px;
            }
          }
        }
      }
    }

    .coupon-define {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      gap: 10px;
      padding: 15px;
      background-color: #fff;
      box-shadow: 0px -2px 25px 0px rgba(0, 0, 0, 0.05);

      .cancel {
        flex: 0.5;
        background: rgba(255, 118, 39, 0.1);
        color: #ff7627;
        border: none;
      }

      .submit {
        flex: 1;
        background: linear-gradient(180deg, rgb(255, 167, 99), rgb(250, 114, 12) 100%);
        color: #ffffff;
        border: none;
      }
    }
  }
}

::v-deep .van-cell__value {
  color: #eb5757;
  font-size: 15px;
  font-weight: 400;
}

::v-deep .van-cell__right-icon {
  color: #eb5757;
  font-size: 15px;
  font-weight: 400;
}

::v-deep .van-tag--plain {
  position: absolute;
  top: 50%;
  transform: translate(0, -42%);
  background-color: #fff;
  margin-left: 5px;
  border-radius: 5px;
  color: #eb5757;
}

::v-deep .van-checkbox {
  width: 25px;
}

.active {
  border: 2px solid #ff7627 !important;
}

.active-topround {
  border: 2px solid #ff7627 !important;
  border-top: 3px solid #fff !important;
  top: -2.5px !important;
}

.active-bottomround {
  border: 2px solid #ff7627 !important;
  border-bottom: 3px solid #fff !important;
  bottom: -2.5px !important;
}
</style>
